import React from 'react';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/styles.css';

const Footer = () => {
    return (
        <FooterStyles>
        <div>
            <p>Andrew Ta &copy; Copyright 2021</p>
        </div>
        </FooterStyles>
    )
}

const FooterStyles = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: #1f1c1c;
  text-align: center;
  padding: 0.1rem 0;

`

export default Footer;