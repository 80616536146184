import React from 'react';
import styled from 'styled-components';

import {Link} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/styles.css';
import image from 'assets/img/banner-image.jpg'



const HomePageBanner = () => {
  return (
    <BannerStyles>
      <div>
          <figure className="position-relative">
            <img src={image} alt="banner" className="img-fluid"/>
            <figcaption>
              <h1>Hi, I'm Andrew!</h1>
              <p>I'm a software developer</p> 
              <p>based in Edmonton, AB</p> 
              <p>who creates dynamic websites,</p>
              <p>web apps, and mobile apps.</p>
              <li><Link to="/about">Learn More</Link></li>
            </figcaption>
          </figure>
      </div>
    </BannerStyles>
  )
}


const BannerStyles = styled.div`
  container: {
    top: 0;
    left: 0;   
    width: '100%';
    height: '100%';
  }
  h1 {
        font-size: 2.25rem;
        color: #1f1c1c;
        font-weight: bold;
        margin-bottom: 1rem;
  }
  p {
        color:#cccaba;
  }
  a {
    text-decoration: none;
    color: #1f1c1c;
    border: 2px solid black;
    border-radius: 3px;
    padding: 0.11rem 0.5rem 0.25rem 0.5rem;
  }
  li {
    margin-top: 1.5rem;
  }
  a:hover {
    color: #cccaba;
    border: 2px solid #8f6517;
    background: #8f6517;
  }

  figcaption {
    position: absolute;
    top: 2rem;
    font-size: 1.5em;
    margin-left: 3rem;
  }

  @media (max-width:768px) {

    figcaption{
        position: inherit;
        bottom: inherit;
        margin: 0.25rem 0px;
        font-size: 1rem;
    }

    p{
      color: #1f1c1c;
    }
  }

  @media (min-width: 768px){    
    figcaption{
        position: absolute;
        top: 5rem;
        font-size: 1.25em;
        margin-left: 3.5rem;
    }
  }

  @media (min-width: 1100px) {
    h1{
      font-size: 3rem;
    }
    figcaption{
        position: absolute;
        top: 8rem;
        font-size: 1.25em;
        margin-left: 6rem;
    }
  }
    
  @media (min-width: 1460px) {
    h1{
      font-size: 3.5rem;
    }
    figcaption{
        position: absolute;
        top: 8rem;
        font-size: 1.75rem;
        margin-left: 6rem;
    }
  }

  @media (min-width: 1750px) {
    h1{
      font-size: 4rem;
    }
    figcaption{
        position: absolute;
        top: 10rem;
        font-size: 1.75rem;
        margin-left: 8rem;
    }
  }

  @media (min-width: 2000px) {
    h1{
      font-size: 4rem;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 1800px;
    }
    figcaption{
        position: absolute;
        top: 16rem;
        font-size: 1.75rem;
        margin-left: 30rem;
    }
  }
  
  @media (min-width: 3000px) {
    h1{
      font-size: 4rem;
    }
    img {
      height: 2000px;
    }
    figcaption{
        position: absolute;
        top: 16rem;
        font-size: 1.75rem;
        margin-left: 55rem;
    }
  }
`


export default HomePageBanner;