import React from 'react'
import styled from 'styled-components'

import ContactPageBanner from 'components/banner/ContactPageBanner'
import ContactForm from 'components/forms/FormInput'

const ContactPageStyles = styled.header `
    /* text-align:center; */
`

const ContactPage = (props) => {
    return ( 
        <ContactPageStyles>
            <ContactPageBanner/>
            <ContactForm/>
        </ContactPageStyles>
     );
}
 
export default ContactPage;