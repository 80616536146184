import React from 'react';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/styles.css';

import CarouselItem from 'components/carousel/Carousel';

import phpIcon from 'assets/img/php-logo.png'
import mysqlIcon from 'assets/img/mysql-logo.png'
import cssIcon from 'assets/img/css-logo.png'
import htmlIcon from 'assets/img/html-logo.png'
import jsIcon from 'assets/img/js-logo.png'
import kotlinIcon from 'assets/img/kotlin-logo.jpg'

import FilterImage from 'assets/img/car-catalog/filter-page.jpg'
import EditImage from 'assets/img/car-catalog/edit-page.jpg'
import AdminImage from 'assets/img/car-catalog/admin-features.jpg'

import ScanImage from 'assets/img/barcode-scanner/barcodeapp-scan.jpg'
import ResultImage from 'assets/img/barcode-scanner/barcodeapp-result.jpg'
import ArchiveImage from 'assets/img/barcode-scanner/barcodeapp-archive.jpg'

const PortFolioItem = (props) => {
    return ( 
        <PortFolioItemStyles {...props}>
            <div class="d-flex flex-sm-column">
            <div class="card m-auto mt-4 col-sm-9 col-md-8 col-lg-6 col-xl-4 border-secondary">
                <CarouselItem nextLabel={""} prevLabel={""} firstSlide={FilterImage} secondSlide={EditImage} thirdSlide={AdminImage} fourthSlide={FilterImage} fifthSlide={EditImage} sixthSlide={AdminImage} seventhSlide={FilterImage} eighthSlide={EditImage}/>
                <div class="card-body">
                    <h5 class="card-title border-top border-bottom text-center">Car Catalog</h5>
                    <p class="card-text">This web app is a catalog full of vehicles that I like. It allows user's to browse the catalog and filter the catalog using various methods. Once logged in, the admin has the ability to create, update, and delete vehicle entries.</p>

                    <div class="links d-flex justify-content-center m-2 mb-3">
                        <a href="https://at-car-catalog.000webhostapp.com/" class="btn btn-warning m-1">Visit</a>
                        <a href="https://github.com/Andrew-Ta/car-catalog" class="btn btn-warning m-1">GitHub</a>
                    </div>                    
                    <div class="language-icons">
                        <img src={phpIcon} alt="php icon"/>
                        <img src={cssIcon} alt="css icon"/>
                        <img src={htmlIcon} alt="html icon"/>
                        <img src={jsIcon} alt="js icon"/>
                        <img src={mysqlIcon} alt="mysql icon"/>
                    </div>
                </div>
            </div>
            <div class="card m-auto mt-4 col-sm-9 col-md-8 col-lg-6 col-xl-4  border-secondary">
                <CarouselItem nextLabel={""} prevLabel={""} firstSlide={ScanImage} secondSlide={ResultImage} thirdSlide={ArchiveImage} fourthSlide={ScanImage} fifthSlide={ResultImage} sixthSlide={ArchiveImage} seventhSlide={ScanImage} eighthSlide={ResultImage}/>
                <div class="card-body">
                    <h5 class="card-title border-top border-bottom text-center">Barcode Scanner</h5>
                    <p class="card-text">I created this mobile app to learn how to access camera permissions, how to translate a barcode image into a barcode number, and how to interface with an API using Kotlin.</p>

                    <div class="links d-flex justify-content-center m-2 mb-3">
                        <a href="https://www.youtube.com/watch?v=EWD_QK9F0nw" class="btn btn-warning m-1">Video Demo</a>
                        <a href="https://github.com/Andrew-Ta/barcode-scanner-app" class="btn btn-warning m-1">GitHub</a>
                    </div>                    
                    <div class="language-icons">
                        <img src={kotlinIcon} alt="kotlin icon"/>
                    </div>
                </div>
            </div>
            </div>
        </PortFolioItemStyles>
     );
}

const PortFolioItemStyles = styled.div`
    h5 {
        font-weight: 600;
        padding: 1rem;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
    }

    .language-icons {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            object-fit: cover;
            height: 48px;
            width: 48px;
        }
    }

`


export default PortFolioItem;