import React from 'react'
import styled from 'styled-components'

import PortfolioPageBanner from 'components/banner/PortfolioPageBanner'
import PortFolioItem from 'components/portfolioitems/PortfolioItem';

const PortfolioPage = (props) => {
    return ( 
        <PortfolioPageStyles>
            <PortfolioPageBanner/>
            <PortFolioItem/>
        </PortfolioPageStyles>
     );
}

const PortfolioPageStyles = styled.div`
    padding-bottom: 11rem;
    background: #f5f5f5;

`





export default PortfolioPage;