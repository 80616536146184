import React from 'react';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/styles.css';

import selfie from 'assets/img/headshot.jpg';

const AboutPageBanner = () => {
  return (
    <section>
      <BannerStyles>
        <h1>About Me</h1>
      </BannerStyles>
      <AboutMeStyles>
        <div class="selfie">
          <img src={selfie} alt="selfie" className="img-fluid"/>
        </div>
        <div class="bio">
          <p>I am a born and raised Albertan who has previously worked as a heavy duty mechanic. I loved to take broken things apart to figure out how they worked and coming up with a solution to fixing it. This passion for problem solving and my curious nature has proven useful in my transition from being a mechanic to becoming a great software developer.</p>
          <p>I enjoy making web and mobile apps. At the moment, I have taken an interest and am focused on learning how to make great and useful web apps using the React library.</p>
          <p>Some of my interests outside of programming are lifting weights, biking, snowboarding, automobiles, playing video games, and I am also learning how to film and edit videos.</p>
        </div>
      </AboutMeStyles>
    </section>

  )
}

const AboutMeStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 3rem 5rem 3rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  justify-content: center;
  text-align: start;


  p {
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  .selfie {
    padding: 0;
  }

  .bio {
    padding: 0;
    margin-top: 2rem;
  }

  @media (min-width:768px) {
    padding: 2rem;

    .selfie {
      width: 25%;
      img {
        padding: 2rem 1rem;
      }
    }
    .bio {
      width: 55%;
      padding: 2rem 1rem;
      margin-top: 0;
    }
  }

  @media (min-width:1120px) {
    .selfie {
      width: 300px;
      img {
        max-width: 300px;
      }
    }
    .bio {
      width: 35%;
      margin-top: 0;    
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media (min-width:1450px) {
    .selfie {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      width: 25%;
    }
  }

  @media (min-width:1750px) {
    .bio {
      width: 25%;
    }
  }

  @media (min-width:2000px) {
    .selfie {
      width: 10%;
    }
    .bio {
      width: 30%;
      }
  }

  @media (min-width:2400px) {
    .selfie {
      width: 10%;
    }
    .bio {
      width: 25%;
      }
  }

  @media (min-width:3000px) {
    .selfie {
      width: 10%;
    }
    .bio {
      width: 20%;
      }
  }


`

const BannerStyles = styled.div`
  h1 {
    padding: 2rem 0;
    font-size: 3rem;
    text-align: center;
    color: #1f1c1c;
    font-weight: bold;  
  }

  @media (min-width: 768px){
    h1{
      font-size: 4rem;
      padding-bottom: 0;
    }
  }

  @media (min-width: 1460px) {
    h1{
      font-size: 5rem;
    }
  }

  @media (min-width: 2000px) {
    h1{
        font-size: 6rem;
    }
  }
`

export default AboutPageBanner;