import React from 'react';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/styles.css';

const ContactPageBanner = () => {
    return(       
        <HeaderStyles>
            <div>                        
                <h1>Contact</h1>
                <h3>Want to get in touch to discuss an opportunity? Feel free to contact me by email, connect with me on LinkedIn, or leave a message in the contact form.</h3>
            </div>    
        </HeaderStyles>
    )
}

const HeaderStyles = styled.div`
    text-align: center;  
    color: #1f1c1c;
    padding: 0 2rem 2rem 2rem;

    h1 {
        font-size: 3.5rem;
        font-family: 'Commissioner', sans-serif;
        font-weight: bolder;        
        margin: 1.5rem;
    }
    h3 {
        font-size: 1.5rem;
    }

    @media (min-width: 768px) {
        h3{
            padding: 0 6rem;
        }
    }        
    @media (min-width: 1180px) {
        h3{
            padding-left: 18%;
            padding-right: 18%;
        }
    } 
    @media (min-width: 1440px) {
        h3{
            padding: 0;
            padding-left: 24%;
            padding-right: 24%;
        }
    } 
    @media (min-width: 1770px){
        h1{
            font-size: 4rem;
            padding-top: 1rem;
        }
        h3{
            padding-top: 1rem;
            padding-left: 28%;
            padding-right: 28%;
        }
    }
    @media (min-width: 2200px){
        h1 {
            font-size: 5rem;
        }
        h3{
            padding-left: 32%;
            padding-right: 32%;
        }
    }
    @media (min-width: 2500px){
        h3{
            padding-left: 35%;
            padding-right: 35%;
            padding-bottom: 2rem;
        }
    }
    @media (min-width: 3000px){
        h3{
            padding-left: 40%;
            padding-right: 40%;
        }
    }
    @media (min-width: 3500px){
        h3{
            padding-left: 40%;
            padding-right: 40%;        
        }
    }
    @media (min-width: 4000px){
        h3{
            padding-left: 40%;
            padding-right: 40%;        
        }
    }
   
`

export default ContactPageBanner;