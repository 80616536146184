import React from 'react';
import styled from 'styled-components';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import NavBar from 'components/navbar/NavBar'
import HomePage from 'pages/HomePage'
import AboutPage from 'pages/AboutPage';
import ContactPage from 'pages/ContactPage';
import SkillsPage from 'pages/SkillsPage';
import PortfolioPage from 'pages/PortfolioPage';
import PageNotFound from 'pages/404'
import Footer from 'components/footer/Footer'

function App() {
  return (
    <Container>
      <NavBar/>
      <Router>
        <Switch>
          <Route exact path="/"><HomePage/></Route>
          <Route path="/about"><AboutPage/></Route>
          <Route path="/contact"><ContactPage/></Route>
          <Route path="/skills"><SkillsPage/></Route>
          <Route path="/portfolio"><PortfolioPage/></Route>
          <Route path="*"><PageNotFound/></Route>
        </Switch>
      </Router>
      <Footer/>
    </Container>


  );
}

const Container = styled.div`
  background: #ebe1cf;
  height: 100vh;
  padding: 0;
  margin: 0;
  
  
`;

export default App;
