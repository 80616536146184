import React from 'react'
import styled from 'styled-components'
import SkillsPageBanner from 'components/banner/SkillsPageBanner'

const SkillsPageStyles = styled.header `
    text-align:center;
    margin: auto 0;

    p{
        color:#76748a;
    }
`

const SkillsPage = (props) => {
    return ( 

        <SkillsPageStyles>
            <SkillsPageBanner/>
        </SkillsPageStyles>

     );
}
 
export default SkillsPage;