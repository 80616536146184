import React from 'react'
import styled from 'styled-components'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/styles.css';

import image from 'assets/img/portfolio-banner.jpg';

const PortfolioPageBanner = () => {
    return (
    <section>
      <BannerStyles>
          <figure className="position-relative">
              <img src={image} alt="banner"/>
              <figcaption>
                  <h1>Portfolio</h1>
              </figcaption>            
          </figure>
      </BannerStyles>

    </section>
    )
}

const BannerStyles = styled.div`
  top: 0;
  left: 0;   
  width: 100%;
  height: 100%;
  background: #f5f5f5;

  img {
    max-width: 100%;
    height: auto;
  }

  h1 {
    font-size: 3rem;
    text-align: center;
    color: #1f1c1c;
    font-weight: bold;
  }

  figcaption {
    position: absolute;
    top: 15%;
    left: 8%;
    right: 8%;
    bottom: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    background: #f5f5f5;
    margin: 0;
    opacity: 92%;    
  }

  @media (min-width: 470px){
    figcaption{
      top: 20%;
      left: 20%;
      right: 20%;
      bottom: 20%;
    }    
  }

  @media (min-width: 768px){
    h1{
      font-size: 4rem;
    }
    figcaption{
      top: 22%;
      left: 20%;
      right: 20%;
      bottom: 22%;
      opacity: 92%;
    }    
  }

  @media (min-width: 920px) {
    h1{
      font-size: 5rem;
    }
    figcaption{
        top: 22%;
        left: 20%;
        right: 20%;
        bottom: 22%;
    }
  }

  @media (min-width: 1120px) {
    img {
      object-fit: cover;
      width: 100%;
      height: 360px;
    }
    figcaption{
    }
  }
`

export default PortfolioPageBanner;