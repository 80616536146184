import React from 'react'
import styled from 'styled-components'

import HomePageBanner from 'components/banner/HomePageBanner'

const HomePageStyles = styled.header `
    text-align:center;
    margin: auto 0;

    svg{
        width: 80px;
    }

`

const HomePage = (props) => {
    return ( 

        <HomePageStyles>
            <HomePageBanner/>
        </HomePageStyles>

     );
}
 
export default HomePage;