import React from 'react';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/styles.css';

import image from 'assets/img/desk-setup.jpg';
import webdev from 'assets/img/webdev-icon.png'
import websites from 'assets/img/website-icon.png'
import databases from 'assets/img/database-icon.png'
import mobile from 'assets/img/mobile-logo.png'


const SkillsPageBanner = () => {
  return (
    <section>
      <BannerStyles>
        <figure className="position-relative">
            <img src={image} alt="banner"/>
            <figcaption>
                <SkillsStyles>
                  <div class="skills-header">
                    <h1>Technical Skills</h1>
                  </div>
                  <div class="skills-sections">
                    <div class="skills">
                      <div class="skill-title">
                          <img src={websites}/>
                          <h3>Websites</h3>
                      </div>
                      <ul>
                          <li>HTML5</li>
                          <li>CSS3</li>
                          <li>JavaScript</li>
                          <li>Bootstrap</li>
                      </ul>
                  </div>
                  <div class="skills">
                      <div class="skill-title">
                          <img src={databases}/>
                          <h3>Databases</h3>
                      </div>
                      <ul>
                          <li>SQL</li>
                          <li>phpMyAdmin</li>
                          <li>Microsoft SQL Server</li>
                          <li>Linq</li>
                          <li>LinqPad</li>
                      </ul>
                  </div>
                  <div class="skills">
                      <div class="skill-title">
                          <img src={webdev}/>
                          <h3>Web Apps</h3>
                      </div>
                      <ul>
                          <li>C#</li>
                          <li>ASP.Net</li>
                          <li>Razor</li>
                          <li>JavaScript</li>
                          <li>React</li>
                          <li>PHP</li>
                      </ul>
                  </div>
                  <div class="skills">
                      <div class="skill-title">
                          <img src={mobile}/>
                          <h3>Mobile Apps</h3>
                      </div>
                      <ul>
                          <li>Kotlin</li>
                          <li>Android Studio</li>
                      </ul>
                  </div>
                </div>
              </SkillsStyles>
            </figcaption>            
          </figure>
      </BannerStyles>                

    </section>

  )
}

const SkillsStyles = styled.div`
  text-align: start;
  background: #f5f5f5;
  /* padding: 2rem; */
  color: #1f1c1c;


  ul {
    margin-bottom: 1rem;
    text-align: center;
  }

  li {
    margin-left: 2.3rem;
    list-style: none;  
    font-size: 1.3rem;
  }

  .skills-sections {
    padding-bottom: 10rem;
  }
  .skill-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    img {
      height: 1.4rem;
      width: 1.4rem;
      margin-right: 0.75rem;
    }
  }

  @media (min-width:768px) {
    ul {
        text-align: left;
        padding-left: 1rem;

    }

    li {
        list-style: outside;
        list-style: square;        
    }
    .skills-sections {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 0;
    }
    .skills {
      width: 25%;
    }
  }

`

const BannerStyles = styled.div`
  top: 0;
  left: 0;   
  width: 100%;
  height: auto;
  background: #f5f5f5;

  img {
    max-width: 100%;
    height: auto;
  }

  h1 {
        font-size: 2.5rem;
        text-align: center;
        color: #1f1c1c;
        font-weight: bold;
        padding: 2rem 0 1rem 0;
        margin-bottom: 1rem;
        border-bottom: 1px solid #1f1c1c;
  }
  h3 {
    font-size: 1.2rem;
    font-weight: 700;
  }

  li {
    font-size: 1rem;
  }

  figcaption {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1rem;
    background: #f5f5f5;
    padding: 0 2rem;
    margin: 0;
    opacity: 92%;
  }

  @media (max-width:768px) {
    figcaption{
        position: inherit;
        bottom: inherit;
        font-size: 1rem;
    }
  }

  @media (min-width: 768px){
    img {
      object-fit: cover;
      width: 100%;
      height: 900px;
    }
    figcaption{
        position: absolute;
        top: 30%;
        left: 5%;
        right: 5%;
        bottom: 30%;
    }    
  }

  @media (min-width: 920px) {
    h1{
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    figcaption{
        top: 30%;
        left: 15%;
        right: 15%;
        bottom: 30%;
    }
  }

  @media (min-width: 1120px) {
    img {
      object-fit: cover;
      width: 100%;
      height: 1080px;
    }
    figcaption{
        top: 33%;
        left: 20%;
        right: 20%;
        bottom: 33%;
    }
  }
    
  @media (min-width: 1460px) {    
    h1 {
      margin-bottom: 3rem;
    }    
    h3 {
      font-size: 1.3rem;
    }
    li {
      font-size: 1.1rem;
    }
    figcaption{
        top: 30%;
        left: 25%;
        right: 25%;
        bottom: 30%;
    }
  }

  @media (min-width: 1750px) {
    figcaption{
        left: 28%;
        right: 28%;
    }

  }

  @media (min-width: 2000px) {
    figcaption{
        left: 30%;
        right: 30%;
    }
  }

  @media (min-width: 2400px) {
    figcaption{
        left: 32%;
        right: 32%;
    }
  }
  
  @media (min-width: 3000px) {
    figcaption{
      left: 35%;
      right: 35%;
    }
  }
`


export default SkillsPageBanner;