import React, {useState} from 'react';
import styled from 'styled-components'
import {Link} from 'react-router-dom'

import github from 'assets/img/github-icon.png'
import linkedin from 'assets/img/linkedin-icon.png'
import brandlogo from 'assets/img/andrew-ta-logo.png'


const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (  
        <StyledNav>
            <Logo href="/"> 
                <span><img src={brandlogo} alt="brand logo" /></span>ndrew<span>Ta</span>         
            </Logo>
            <Hamburger onClick={() => setIsOpen(!isOpen)}>
                <span></span>
                <span></span>
                <span></span>
            </Hamburger>
            <Menu isOpen={isOpen}>                        
                <MenuLink href="/about">About</MenuLink>        
                <MenuLink href="/portfolio">Portfolio</MenuLink>
                <MenuLink href="/skills">Skills</MenuLink>
                <MenuLink href="/contact">Contact</MenuLink>
            </Menu>
            <Links isOpen={isOpen}>
                <a href="https://www.linkedin.com/in/andrew-ta-software/"><img src={linkedin}/></a>
                <a href="https://github.com/Andrew-Ta"><img src={github}/></a>
            </Links>
        </StyledNav>
    );
}

const StyledNav = styled.nav`
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: #dbd2d2;

`;

const Hamburger = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;

    span {
        height: 2px;
        width: 25px;
        background: #404044;
        margin-bottom: 4px;
        border-radius: 5px;
    }

    @media (max-width: 768px){
        display: flex;
    }

`

const MenuLink = styled.a`
    padding: 1rem 1.3rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: #404044;
    transition: all 0.3s ease-in;
    font-size: 1.1rem;

    &:hover {
        color:  #8f6517;

    }
`

const Menu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-weight: 500;

    @media (max-width: 768px){
        overflow: hidden;
        flex-direction: column;
        width: 100%;
        max-height: ${({isOpen}) => (isOpen ? "300px" : "0")};
        transition: max-height 0.3s ease-in;
    }
`

const Links = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    img {
        height: 1.75rem;
        width: 1.75rem;
        margin: 1rem 0.5rem;
        border: none;
    }

    @media (max-width: 768px){
        overflow: hidden;
        flex-direction: column;
        width: 100%;
        max-height: ${({isOpen}) => (isOpen ? "300px" : "0")};
        transition: max-height 0.3s ease-in;
    }
`

const Logo = styled.a`
    text-decoration: none;
    padding: 1rem 0;
    font-size: 1.7rem;
    font-weight: 800;
    color: #404044;
    transition: 0.3s ease-in-out;

    img {
        height: 1.7rem;
        margin-bottom: 0.5rem;
    }

    span {
        font-size: 1.3rem;
        font-weight: 300;
    }

    &:hover {
        color:  #8f6517;    
    }

`


export default NavBar;