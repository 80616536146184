import React, { useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import * as toastr from "toastr";
import "toastr/build/toastr.min.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/styles.css';

import email from 'assets/img/email-logo.png';
import github from 'assets/img/github-icon.png'
import linkedin from 'assets/img/linkedin-icon.png'

const ContactForm = () => {
    const [name, setName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [message, setMessage] = useState('');
    const [isValid, setIsValid] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('contact_service', 'contact_form', e.target, 'user_gP4ibcZW7YVIiLhsQcjVP')
            .then((result) => {
                console.log(result.text);
                toastr.success('Contact form sent');  

                setName('');
                setEmailAddress('');
                setMessage('');
                setIsValid(false);

            }, (error) => {
                console.log(error.text);
                toastr.error('Failed to send form');
            });       
    };

    const handleChange = (e) => {
        setIsValid(true);
        if(!name){
            setIsValid(false);
        } else {
            setIsValid(true);
        }
        if(!emailAddress){
            setIsValid(false);
        } else {
            setIsValid(true);
        }
        if(!message){
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    }


    return (            
        <section>
            <FlexContainer>
                <ContactStyles>
                    <div className="contact-logo"> 
                        <div className="contact-info">                
                            <a href="mailto:info@andrewta.ca"><img src={email} alt="email"/></a>
                            <a href="mailto:info@andrewta.ca">info@andrewta.ca</a>
                        </div>
                        <div className="contact-info">
                            
                            <a href="https://www.linkedin.com/in/andrew-ta-software/"><img src={linkedin} alt="linkedin icon"/></a>
                            <a href="https://www.linkedin.com/in/andrew-ta-software/">LinkedIn</a>
                        </div>
                        <div className="contact-info">                
                            
                            <a href="https://github.com/Andrew-Ta"><img src={github} alt="github icon"/></a>
                            <a href="https://github.com/Andrew-Ta">GitHub</a>
                        </div>          
                    </div>
                </ContactStyles>   
                <FormStyles>
                    <form id="contact-form" onSubmit={e => sendEmail(e)} onChange={e => handleChange(e)}>
                        <input type="hidden" name="contact_number"></input>
                        <div>           
                            <input type="text" name="name" placeholder="Enter your name" value={name} onChange={e => setName(e.target.value)}></input>
                        </div>
                        <div>
                            <input type="email" name="email" placeholder="Enter your email address" value={emailAddress} onChange={e => setEmailAddress(e.target.value)}></input>
                        </div>
                        <div>
                            <textarea name="message" placeholder="Enter your message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                        </div>
                        <input className="submit" type="submit" value="Send" disabled={!isValid}></input>
                    </form>
                </FormStyles> 
            </FlexContainer>            
        </section>
    )        
    
}

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 10rem;

    @media (min-width: 768px){
        flex-direction: row;
        justify-content: center;
        padding-bottom: 0;
    }    

`

const ContactStyles = styled.div`   
    text-align: center;
    font-size: 1.2rem;
    color: #1f1c1c;    
    justify-content: center;
    margin-right: 2rem;

    .contact-logo {
        display: flex;
        flex-direction: column;
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 1.5rem;
    }

    h4 {
        margin-bottom: 1.5rem;
    }

    a {
        text-decoration: none;
        color: #1f1c1c;
    }
    img {
        height: 2rem;
        width: 2rem;
    }

`

const FormStyles = styled.div`
    color: #1f1c1c;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    text-align: center;

    input {
        width: 100%;
        padding: 0.5rem;        
        outline: none;
        border-radius: 3px;
        border: none;
        margin-bottom: 1rem;
    }

    textarea {
        width: 100%;
        height: 200px;
        padding: 0.5rem;
        margin-bottom: 1rem;
        outline: none;
        resize: none;
        border-radius: 3px;
        border: none;
    }

    .submit { 
        font-weight: 600;
        width: 50%;
        padding: 0.5rem 0;
        border-radius: 5px;
        background-color: #cccaba;
        box-shadow: 1px 1px 2px 1px;
    }

    .submit:hover{
        background-color: #8f6517;
    }

    @media (min-width: 768px){
        width: 60%;
        input {
            width: 100%;
            outline: none;
        }
        textarea {
            width: 100%;
            height: 10rem;
            resize: none;
            outline: none;
        }
        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .submit {
            width: 25%;
            padding: 0.2rem 0;
        }
    }

    @media (min-width: 1180px){
        width: 50%;
        padding: 0 1rem 1rem;
        margin: 0;
    }

    @media (min-width: 1440px) {
        width: 40%;
    } 

    @media (min-width: 1770px){
        width: 30%;
    }

    @media (min-width: 2200px){
        width: 22%;
    }
    @media (min-width: 2500px){
        width: 20%;
    }
    @media (min-width: 3000px){
        width: 18%;
    }
    @media (min-width: 3500px){
        width: 12%;
    }

`

export default ContactForm;