import React from 'react'
import styled from 'styled-components'

import 'bootstrap/dist/css/bootstrap.min.css';

import AboutPageBanner from 'components/banner/AboutPageBanner'
import CarouselItem from 'components/carousel/Carousel'

import canoe from 'assets/img/aboutme/canoe.jpg'
import snowboard from 'assets/img/aboutme/snowboard.jpg'
import sim from 'assets/img/aboutme/sim-racing.jpg'
import working from 'assets/img/aboutme/working.jpg'
import bike from 'assets/img/aboutme/bike.jpg'
import deadlift from 'assets/img/aboutme/old-deadlift.jpg'
import squat from 'assets/img/aboutme/squat.jpg'
import shrimp from 'assets/img/aboutme/shrimp.jpg'

const AboutPage = (props) => {
    return ( 

        <AboutPageStyles>
            <AboutPageBanner/>
            <CarouselStyles>
                <CarouselItem nextLabel={""} prevLabel={""} firstSlide={canoe} secondSlide={snowboard} thirdSlide={sim} fourthSlide={deadlift} fifthSlide={working} sixthSlide={bike} seventhSlide={squat} eighthSlide={shrimp}/>
            </CarouselStyles>
        </AboutPageStyles>
     );
}

const AboutPageStyles = styled.header `
    text-align:center;
    margin: auto 0;

    p{
        color:#76748a;
    }
` 

const CarouselStyles = styled.div`
    padding-bottom: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width:1120px) {
        padding-left: 15%;
        padding-right: 15%;
    }
    @media (min-width:1450px) {
        padding-left: 20%;
        padding-right: 20%;
    }
    @media (min-width:1750px) {
        padding-left: 25%;
        padding-right: 25%;
    }
    @media (min-width:2400px) {
        padding-left: 28%;
        padding-right: 28%;
    }
    @media (min-width:3000px) {
        padding-left: 32%;
        padding-right: 32%;
    }
    @media (min-width:3400px) {
        padding-left: 36%;
        padding-right: 36%;
    }

`
export default AboutPage;