import React from 'react';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/styles.css';
import Carousel from 'react-bootstrap/Carousel'

const CarouselItem = (props) => {
    return ( 
        <CarouselStyles>
            <Carousel controls={props.controls} nextLabel={props.nextLabel} prevLabel={props.prevLabel}>
                <Carousel.Item>
                    <img className="d-block w-100" src={props.firstSlide} alt="First slide"/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={props.secondSlide} alt="Second slide"/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={props.thirdSlide} alt="Third slide"/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={props.fourthSlide} alt="Fourth slide"/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={props.fifthSlide} alt="Fifth slide"/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={props.sixthSlide} alt="Sixth slide"/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={props.seventhSlide} alt="Seventh slide"/>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={props.eighthSlide} alt="Eighth slide"/>
                </Carousel.Item>
            </Carousel>
        </CarouselStyles>
     );
}

const CarouselStyles = styled.div`
`


export default CarouselItem;